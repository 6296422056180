import { useEffect } from "react";
import { useDispatch } from "react-redux";
import AOS from "aos";
import Home from "./landing-page/home";

import {
  handleAudioTypes,
  handleHomePostInitialTrack,
} from "../redux-store/actions/albums";
import { wrapper } from "../redux-store";
import { decrypt } from "../common/function/storage";

export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async (context) => {
    // const cookies = context?.req?.cookies?.refresh_token
    let access_token = context?.req?.cookies?.access_token;
    let decryptedValue = decrypt(access_token);
    if(decryptedValue){
      return {
        redirect: {
          permanent: false,
          destination: "/store/explore",
        },
        props: {},
      };
    }
    let props = {
      audioTypes: [],
      headProps: {
        title:"BattleBards | Orchestrate your tabletop role-playing games",
        desc:`Epic Level RPG Gaming 
        Audio Awaits!  BattleBards brings your games to life through the 
        largest catalog of professionally created audio specifically crafted 
        for an immersive gaming experience`,
        keyword:`BattleBards, battle bards, 
        tabletop sounds, tabletop audio, tabletop games, Dungeons and Dragons, D&D, Pathfinder, Role-playing games music, RPG Music, soundscapes, sound effects, RPG audio, gaming audio, ambient audio`,
        images: "https://bb20.s3.us-east-2.amazonaws.com/cover/dragon-sound/w-250-h-325.jpg"
      }
    /*   homePostInitialTrack: [], */
    };
    try {
      props.audioTypes = await store.dispatch(handleAudioTypes());
    } catch (error) {
      console.log("error", error);
    }
    /* try {
      popularTrack = {
        track_category: "popular",
      };
      props.homePostInitialTrack = await store.dispatch(
        handleHomePostInitialTrack(
          popularTrack,
          context?.req?.cookies?.access_token
        )
      );
    } catch (error) {
      console.log("error", error);
    } */
    return {
      props,
    };
  }
);

const Index = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "GET_AUDIO_TYPES",
      payload: props?.audioTypes,
    });
  }, [props?.audioTypes]);

 /*  useEffect(() => {
    let temp = props?.homePostInitialTrack?.findIndex(
      (item) => item?.title === "Duntrimir and Ellassar"
    );
    let spiceArray = props?.homePostInitialTrack.splice(temp, 1);
    let findObj = spiceArray?.find(
      (item) => item?.title === "Duntrimir and Ellassar"
    );
    props?.homePostInitialTrack.unshift(findObj);

    dispatch({
      type: "GET_POPULAR_INITIAL_TRACK",
      payload: props?.homePostInitialTrack,
    });
  }, [props?.homePostInitialTrack]); */

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    AOS.init({
      disable: function () {
        var maxWidth = 991;
        return window.innerWidth < maxWidth;
      },
    });
  }, []);

  return (
    <div>
      <Home />
    </div>
  );
};

export default Index;
